@media only screen and (min-width: 900px) {
    .homeContainer {
        height: 100vh;
        

        .container {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 100vw;
            height: 100vh;
            justify-content: center;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 15px;

            .card {
                width: 70%;
                height: max-content;
                background-color: #ffffff8e;
                box-shadow: 0 -1px 34px 0 #7b7b86ab;
                ;
                border-radius: 15px;
                text-align: center;
                padding-bottom: 70px;
                padding-top: 70px;

                .title {
                    font-size: 1.7vw;
                    font-family: "Poppins", sans-serif;
                    font-weight: 800;
                    font-style: bold;
                    color: #2599FB;
                }

                .subTitle {
                    font-size: 20pt;
                    font-family: "Poppins", sans-serif;
                    font-weight: 400;
                    color: #000000;
                }

                .downloadContainer {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-left: 50px;
                    padding-right: 50px;
                    border-radius: 15px;
                    margin-top: 20px;
                    height: 300px;

                    .driverContainer {
                        background-color: white;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1607843137);
                        display: flex;
                        flex-direction: row;
                        justify-content: space-around;
                        border-radius: 15px;
                        border-color: #2599FB;
                        border-style: solid;
                        align-items: center;
                        padding-left: 30px;
                        padding-right: 10px;
                        width: 45%;

                        .imgContainer {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            margin-top: 30px;
                            margin-bottom: 50px;

                            .driverlogo {
                                width: 170px;
                                height: 170px;
                            }

                            .img {
                                position: absolute;
                                bottom: -30px;
                                width: 115px;
                                height: 92px;
                            }

                            .driver {
                                width: 248px;
                                height: 115px;
                                margin-left: -73px;
                            }
                        }

                        .imgsContainer {
                            display: flex;
                            position: relative;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin-top: 30px;
                            margin-bottom: 50px;

                            .imgs {
                                position: absolute;
                                bottom: 31px;
                                width: 115px;
                                height: 92px;
                                left: 64px;
                            }

                            .riderlogo {
                                width: 170px;
                                height: 170px;
                            }

                            .rider {
                                width: 136px;
                                height: 197px;
                            }
                        }
                    }

                    .arrowContainer {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 200px;

                        .buttonContainer:hover {
                            cursor: pointer;
                        }

                        .buttonContainer {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            position: relative;
                            width: 225px;

                            .header {
                                font-family: "Poppins", sans-serif;
                                font-weight: 600;
                                font-size: 1.8em;
                                color: #2599FB;
                                width: 175px;
                            }

                            .arrow {
                                width: 25px;
                                height: 30px;
                                margin-left: 13px;
                            }
                        }

                        .ptag {
                            width: 225px;
                        }

                        .text {
                            font-size: 1.2em;
                            font-family: "Poppins", sans-serif;
                            text-align: left;
                        }
                    }

                    .buttonContainer1:hover {
                        cursor: pointer;
                    }

                    .buttonContainer1 {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        margin-left: -23px;

                        .header {
                            font-family: "Poppins", sans-serif;
                            font-weight: 600;
                            font-size: 30pt;
                            color: #2599FB;
                        }

                        .arrow {
                            width: 25px;
                            height: 30px;
                            margin-left: 13px;
                        }
                    }

                    .ptag {
                        width: 280px;
                        margin-top: -16px;
                    }

                    .text {
                        font-size: 16pt;
                        font-family: "Poppins", sans-serif;
                        text-align: left;
                    }
                }

            }

            .copyright {
                color: white;
                font-weight: 400;
                text-align: center;
                font-size: 1.5em;
                font-family: "Poppins", sans-serif;
                letter-spacing: 0px;
                opacity: 1;
                padding-top: 50px;
            }
        }
    }
}

@media only screen and (max-width: 899px) {
    #video {
        display: none;  
    }
    .homeContainer {
        background-image: url('../../assets/Background_PosterImage.png');
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;
        max-width: 100vw;
        width: 100vw;

        .container {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            max-width: 100vw;

            .card {
                width: 90%;
                height: max-content;
                background-color: #ffffff8e;
                box-shadow: 0 -1px 34px 0 #7b7b86ab;
                ;
                border-radius: 15px;
                text-align: center;
                padding-bottom: 70px;
                padding-top: 70px;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .title {
                    font-size: 1.3em;
                    font-family: "Poppins", sans-serif;
                    font-weight: 800;
                    color: #2599FB;
                }

                .subTitle {
                    font-size: 12pt;
                    font-family: "Poppins", sans-serif;
                    font-weight: 400;
                    color: #000000;
                }

                .downloadContainer {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    border-radius: 15px;
                    height: 320px;
                    width: 500px;
                    padding-top: 30px;
                    padding-bottom: 30px;

                    .driverContainer {
                        background-color: white;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1607843137);
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-radius: 15px;
                        border-color: #2599FB;
                        border-style: solid;
                        align-items: center;
                        padding-bottom: 20px;
                        padding-left: 25px;
                        padding-right: 40px;
                        padding-top: 20px;
                        width: 45%;

                        .imgContainer {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            height: 100%;

                            .driverlogo {
                                width: 87px;
                                height: auto;
                            }

                            .img {
                                position: absolute;
                                bottom: -45px;
                                height: 92px;
                                left: -35px;
                            }

                            .driver {
                                width: auto;
                                height: 65px;
                            }
                        }

                        .imgsContainer {
                            display: flex;
                            position: relative;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            height: 100%;

                            .imgs {
                                position: absolute;
                                bottom: 31px;
                                height: 92px;
                                left: 27px;
                            }

                            .riderlogo {
                                width: 87px;
                                height: auto;
                            }

                            .rider {
                                width: 103px;
                                height: 122px;
                            }
                        }
                    }

                    .arrowContainer {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 180px;

                        .buttonContainer:hover {
                            cursor: pointer;
                        }

                        .buttonContainer {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            width: 180px;

                            .header {
                                font-family: "Poppins", sans-serif;
                                font-weight: 600;
                                color: #2599FB;
                                font-size: 17pt;
                            }

                            .arrow {
                                display: none;
                            }
                        }

                        .ptag {
                            display: none;
                        }

                        .text {
                            font-size: 11pt;
                            font-family: "Poppins", sans-serif;
                            text-align: left;
                            margin-left: 50px;
                            padding: 0px;
                        }
                    }

                    .arrowContainer {
                        .buttonContainer1:hover {
                            cursor: pointer;
                        }

                        .buttonContainer1 {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            margin-left: -23px;

                            .header {
                                font-size: 17pt;
                                font-family: "Poppins", sans-serif;
                                font-weight: 600;
                                color: #2599FB;
                            }

                            .arrow {
                                width: 25px;
                                height: 30px;
                                margin-left: 13px;
                            }

                        }

                        .ptag {
                            width: 280px;
                        }

                        .text {
                            font-size: 11pt;
                            font-family: "Poppins", sans-serif;
                            text-align: left;
                            margin-left: 50px;
                            padding: 0px;
                        }
                    }
                }
            }
        }

        .copyright {
            color: white;
            font-weight: 400;
            text-align: center;
            font-size: 1em;
            font-family: "Poppins", sans-serif;
            letter-spacing: 0px;
            opacity: 1;
            padding-top: 40px;
        }
    }

}